/**
 * Polyfills
 */

/* import 'ie-shim'; */
/* Internet Explorer 9 support */
// eslint-disable-next-line
import 'core-js';
import 'intl';
// Typescript emit helpers polyfill
/* eslint-disable import/no-extraneous-dependencies */
import 'ts-helpers';

import 'web-animations-js';

import 'zone.js/dist/zone';

if (ENV === 'production') {
    // Production

} else {
    // Development
    Error.stackTraceLimit = Infinity;
    /* eslint-disable global-require */
    require('zone.js/dist/long-stack-trace-zone');
}
